import '../scss/app.scss';
import '../scss/app-editor.scss';
import '../scss/print.scss';
import './bootstrap.bundle';
import { UxCpnt } from './components/ux';
import { MapCpnt } from './components/map';
import { TourismCpnt } from './components/tourism';
import { GridCpnt } from './components/grid';
import { WeatherCpnt } from './components/weather';
import { SlidersCpnt } from './components/sliders';
import { SocialCpnt } from './components/social';
import { MenuCpnt } from './components/menu';
import { FormCpnt } from './components/form';

document.addEventListener( "DOMContentLoaded", function () {
    const ObjUxCpnt         = new UxCpnt();
    ObjUxCpnt.loadUxCpnt();
    const ObjMapCpnt        = new MapCpnt();
    const ObjTourismCpnt    = new TourismCpnt();
    const ObjGridCpnt       = new GridCpnt();
    const ObjWeatherCpnt    = new WeatherCpnt();
    const ObjSlidersCpnt    = new SlidersCpnt();
    const ObjSocialCpnt    = new SocialCpnt();
    const ObjMenuCpnt    = new MenuCpnt();
    const ObjFormCpnt    = new FormCpnt();
} );