import 'slick-carousel/slick/slick.min'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import Modernizr from 'modernizr'
import LazyLoad from "vanilla-lazyload"


export class UxCpnt {
    constructor()
    {
        // DO NOTHING
    }
    
    loadUxCpnt()
    {
        this.initializeJsClick()
        this.initializeBootstrpTooltip()
        this.initializeFancybox()
        this.initializeCookieOptIn()
        this.initializeSearchBox()
        this.initializeBackToTop()
        this.initializeSmoothScroll()
        this.initializeLazy()
        this.initializeLangSelector()
    }
    
    initializeJsClick()
    {
        if ( !Modernizr.touchevents ) {
            let ctrl = 0
            $( window ).on( 'keydown', function ( e ) {
                if ( e.which == 17 || e.which == 91 ) {
                    
                    ctrl = true
                }
            } )
            
            $( window ).on( 'keyup', function () {
                ctrl = false
            } )
            
            $( document ).on( 'mouseup', '.js-click', function ( e ) {
                
                if ( e.which == 1 || e.which == 2 ) {
                    let blank = false
                    let url   = $( this ).find( "a" ).attr( "href" )
                    if ( e.which == 2 ) {
                        blank = true
                    } // Clic molette
                    if ( $( this ).find( "a" ).attr( "target" ) == "_blank" ) {
                        blank = true
                    }
                    if ( blank || ctrl ) {
                        window.open( url, '_blank' )
                    } else {
                        window.location = url
                    }
                    return false
                }
                return false
                
            } )
        } else {
            $( document ).on( 'click', '.js-click', function ( e ) {
                
                let blank = false
                let url   = $( this ).find( "a" ).attr( "href" )
                if ( $( this ).find( "a" ).attr( "target" ) == "_blank" ) {
                    blank = true
                }
                if ( blank ) {
                    window.open( url, '_blank' )
                } else {
                    window.location = url
                }
                return false
                
            } )
        }
    }
    
    initializeBootstrpTooltip()
    {
        /*  Bootstrap tooltips
         /* ============================================= */
        if (!Modernizr.touchevents) {
            $('[data-toggle="tooltip"], [rel="tooltip"]').tooltip({ trigger: "hover" });
            $('[data-toggle="popover"]').popover();
            $('[rel="tooltip"]').on('click', function ()
            {
                $(this).tooltip('hide');
            })
        
            $('[data-toggle="popover"]').on('click', function ()
            {
                $(this).toggleClass('popoverActive');
                $('[data-toggle="popover"]').popover('hide');
                $(this).popover('show');
            
            })
        
            $('[rel="tooltip"]').on('hover', function (){
                if( $(this).hasClass( 'popoverActive' ) ) {
                    $(this).tooltip('hide');
                }
            })
        
            $('[rel="tooltip"]').on('show.bs.popover', function() {
                $(this).tooltip('hide');
            })
        }
    }
    
    initializeFancybox()
    {

        $( '.article a:has(>img):not([target="_blank"]):not(".no-fancybox")' ).attr( 'data-fancybox', 'fancybox' )
        
        $( "[data-fancybox='fancybox']" ).fancybox( {
            buttons: [
                "zoom",
                //"share",
                "slideShow",
                //"fullScreen",
                //"download",
                //"thumbs",
                "close"
            ],
            lang: "fr",
            i18n: {
                fr: {
                    CLOSE: Theme.translate.fancyboxClose,
                    NEXT: Theme.translate.fancyboxNext,
                    PREV: Theme.translate.fancyboxPrev,
                    ERROR: Theme.translate.fancyboxError,
                    PLAY_START: Theme.translate.fancyboxStart,
                    PLAY_STOP: Theme.translate.fancyboxStop,
                    FULL_SCREEN: Theme.translate.fancyboxFullScreen,
                    THUMBS: Theme.translate.fancyboxThumbs,
                    DOWNLOAD: Theme.translate.fancyboxDownload,
                    SHARE: Theme.translate.fancyboxShare,
                    ZOOM: Theme.translate.fancyboxZoom
                },
            },
            caption: function ( instance, item ) {
                let caption = $( this ).closest( 'figure' ).find( 'figcaption' ).html()

                if ( !caption ) {
                    caption = $( this ).find( 'img' ).attr( 'title' )
                }

                if ( !caption ) {
                    caption = $( this ).find( 'img' ).attr( 'alt' )
                }

                return caption
            }
        } )
    }
    
    initializeCookieOptIn()
    {
        tarteaucitron.init({
            "useExternalCss": false,
            "privacyUrl": Theme.privacyPolicyURL,
            
            "hashtag": "",
            "cookieName": "tartaucitron",
            
            "orientation": "bottom",
            "showAlertSmall": false,
            "cookieslist": true,
            
            "adblocker": false,
            "AcceptAllCta" : true,
            "highPrivacy": true,
            "handleBrowserDNTRequest": false,
            
            "removeCredit": true,
            "moreInfoLink": true,
            "showExitButton": true
        });
    
        tarteaucitronForceLanguage = Theme.current_locale;
        (tarteaucitron.job = tarteaucitron.job || []).push( 'wordpress' );
        (tarteaucitron.job = tarteaucitron.job || []).push( 'recaptcha' );
        (tarteaucitron.job = tarteaucitron.job || []).push( 'gtag' );
    }
    
    initializeSearchBox()
    {
        $( '.js-wpSearchLayoutOpen' ).on( 'click', function ( e ) {
            e.preventDefault()
            $( '.wpSearchLayout' ).fadeIn()
            setTimeout( function () {
                $( '.wpSearchFormItem--input' ).focus()
            }, 100 )
        } )
        
        $( '.js-wpSearchLayoutClose' ).on( 'click', function ( e ) {
            e.preventDefault()
            $( '.wpSearchLayout' ).fadeOut()
        } )
    }
    
    initializeBackToTop()
    {
        $( '.js-backToTop' ).on( 'click', function ( e ) {
            e.preventDefault()
            
            if ( $( window ).scrollTop() != 0 ) {
                $( 'html, body' ).animate( { scrollTop: 0 }, 300 )
            }
        } )
        
        $( window ).on( 'scroll', function ( e ) {
            
            if ( $( this ).scrollTop() > 250 ) {
                $( '.js-backToTop' ).addClass( 'backToTop--show' )
            } else {
                $( '.js-backToTop' ).removeClass( 'backToTop--show' )
            }
        } )
    }
    
    initializeSmoothScroll()
    {
        $( '.js-scroll' ).click( function () {
            if ( location.pathname.replace( /^\//, '' ) == this.pathname.replace( /^\//, '' ) && location.hostname == this.hostname ) {
                let target = $( this.hash )
                target     = target.length ? target : $( '[name=' + this.hash.slice( 1 ) + ']' )
                if ( target.length ) {
                    $( 'html,body' ).animate( {
                        scrollTop: target.offset().top
                    }, 750 )
                    return false
                }
            }
        } )
    }

    initializeLazy()
    {
        let lazyLoadInstance = new LazyLoad({
            elements_selector: "[data-src], [data-bg]"
        });
    }
    
    /*  Lang selector
     /* ============================================= */
    initializeLangSelector() {
        $('.langCurrent').on('click', function(e) {
            e.preventDefault();
            $('.toolsItem--lang').toggleClass('open');
        });
        
        $('.wrapper').on('click', function(e) {

            if ( ! $(e.target).closest('.toolsItem--lang').length && ! $(e.target).closest('.langCurrent').length ) {

                $('.toolsItem--lang').removeClass('open');
            }
        })
        
        $(document).on('click', '.langSelector a[onclick*="fr|en"]', function() {
            $('body').attr('id', 'en');
        });
        
        $(document).on('click', '.langSelector a[onclick*="fr|fr"]', function() {
            $('body').attr('id', 'fr');
        });
        
        //$(document).on('click', '.langSelector a[onclick*="fr|es"]', function() {
        //    $('body').attr('id', 'es');
        //});
        //
        //$(document).on('click', '.langSelector a[onclick*="fr|it"]', function() {
        //    $('body').attr('id', 'it');
        //});
        //
        //$(document).on('click', '.langSelector a[onclick*="fr|de"]', function() {
        //    $('body').attr('id', 'de');
        //});
        
    }
}
