export class WeatherCpnt {

    constructor()
    {
        this.initializeWeather()
    }

    initializeWeather()
    {
        $(".tab-meteo .bloc-texte .close").click(function () {
            $('.row-date').removeClass('open');

            $(this).toggleClass("open");
            $(this).parent().closest('.row-date').addClass('open');

            $('.row-date.open').next('div').slideToggle(500);

            return false;
        });
    }
}
